import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Button, Grid } from "@material-ui/core";
import { dialogStyles } from "../../theme/dialogStyles";

interface OwnProps {
  open: boolean;
  setOpen(open: boolean): any;
}
type Props = OwnProps;

const StubModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation(["common", "asip"]);
  const classes = dialogStyles();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} keepMounted={true}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h3">Not Available</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent style={{ paddingLeft: 16 }}>
        <Typography
          variant="body1"
          style={{
            marginTop: 24,
            fontSize: 16,
          }}
        >
          Sorry, this feature is not available at the moment.
        </Typography>
        <Grid
          spacing={1}
          style={{ marginTop: 24 }}
          container
          justify="flex-end"
          alignContent="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClose}
              style={{ minWidth: 120 }}
            >
              {t("common:buttons.cancel")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StubModal;
