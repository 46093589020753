import { Button, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";
import { dashboardStyles } from "./styles";
import { PrepFqStatus } from "../../models/users";
import Skeleton from "@material-ui/lab/Skeleton";
import Link from "next/link";
import {
  PREP_FQ,
  PREP_FQ_PDF_VIEWER,
  PREMIUM_DASHBOARD,
  UPGRADE,
} from "../../constants/routes";
import { useTranslation } from "next-i18next";
import StartPrepFqModal from "./StartPrepFqModal";
import usePrepFqProfile from "../prepFq/fetchers/usePrepFqProfile";
import { useAuth } from "../../firebase/useAuth";
import useUser from "../../firebase/useUser";
import useUserMetadata from "../../firebase/useUserMetadata";
import StubModal from "./StubModal";

const PrepFQBanner = () => {
  const { t } = useTranslation(["common", "dashboard"]);
  const classes = dashboardStyles();
  const authUser = useAuth();
  const { user } = useUser(authUser?.user?.uid);
  const { profile, isLoading } = usePrepFqProfile(user?.user_id);
  const { userMetadata } = useUserMetadata(user?.user_id);
  const [open, setOpen] = useState(false);
  const [stubOpen, setStubOpen] = useState(false);
  const getScore = (score: number) => {
    if (!score) {
      return `00`;
    } else if (score > 100) {
      return `100`;
    } else if (score < 10) {
      return `0${score}`;
    } else {
      return score.toFixed(0);
    }
  };
  const getScoreDescription = (
    profile_status: PrepFqStatus,
    page: number,
    is_premium: boolean,
    loading: boolean
  ) => {
    if (loading) {
      return (
        <Skeleton animation="wave" variant="rect" width={350} height={50} />
      );
    } else if (profile_status === "not_started") {
      return "scoreDescription.not_started";
    } else if (profile_status === "in_progress") {
      return "scoreDescription.in_progress";
    } else if (profile_status === "completed" && is_premium) {
      return "scoreDescription.completed_is_premium";
    } else if (profile_status === "completed" && !is_premium) {
      return "scoreDescription.completed";
    } else {
      return "";
    }
  };
  const getCtaButton = (
    profile_status: PrepFqStatus,
    page: number,
    is_premium: boolean,
    loading: boolean
  ) => {
    if (loading) {
      return (
        <Skeleton
          animation="wave"
          variant="rect"
          height={42}
          className={classes.ctaButtonLoader}
        />
      );
    } else if (profile_status === "not_started") {
      return (
        <Button
          onClick={() => {
            setOpen(true);
          }}
          className={classes.ctaButton}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          {t("common:buttons.questionnaire")}
        </Button>
      );
    } else if (profile_status === "in_progress") {
      return (
        <Link passHref href={`${PREP_FQ}/${page}`}>
          <Button
            className={classes.ctaButton}
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
          >
            {t("common:buttons.continue")}
          </Button>
        </Link>
      );
      //todo add premium check  && is_premium
    } else if (profile_status === "completed") {
      return (
        <Link passHref href={`${PREMIUM_DASHBOARD}`}>
          <Button
            className={classes.ctaButton}
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
          >
            {t("common:buttons.open")}
          </Button>
        </Link>
      );
    }
    //todo add premium check
    // else if (profile_status === "completed" && !is_premium) {
    //   return (<Link href={`${UPGRADE}`}>
    //     <Button className={classes.ctaButton} variant="contained" color="secondary" size="large" fullWidth>
    //       {t("common:buttons.upgrade")}
    //     </Button>
    //   </Link>)
    // }
    else {
      return (
        <Button
          onClick={() => {
            setOpen(true);
          }}
          className={classes.ctaButton}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          {t("common:buttons.questionnaire")}
        </Button>
      );
    }
  };
  const shareResults = () => {
    setStubOpen(true);
  };
  return (
    <React.Fragment>
      <StubModal open={stubOpen} setOpen={setStubOpen} />
      <Paper elevation={4} className={classes.bannerCard}>
        <div className={classes.bannerCardContents}>
          <h2 className={classes.bannerTitle}>
            {t("dashboard:prepFq.title1")}
            <span className={classes.blueText}>
              {t("dashboard:prepFq.title2")}
            </span>
          </h2>
          <Grid container>
            <Grid xs={12} item>
              <p className={classes.paragraph}>
                {t("dashboard:prepFq.paragraph")}
              </p>
            </Grid>
          </Grid>
          <Grid spacing={3} container>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              {getCtaButton(
                profile?.prep_fq_status,
                profile?.current_page,
                userMetadata?.is_premium,
                isLoading
              )}
              <div className={classes.splitButtonContainer}>
                <Link passHref href={PREP_FQ_PDF_VIEWER}>
                  <Button
                    classes={{ disabled: classes.splitButtonDisabled }}
                    disabled={profile?.prep_fq_status !== "completed"}
                    className={clsx(
                      classes.splitButton,
                      classes.splitButtonLeft
                    )}
                    size="large"
                    variant="contained"
                    color="secondary"
                  >
                    {t("common:buttons.viewReport")}
                  </Button>
                </Link>
                <Button
                  onClick={shareResults}
                  classes={{ disabled: classes.splitButtonDisabled }}
                  disabled={profile?.prep_fq_status !== "completed"}
                  className={clsx(
                    classes.splitButton,
                    classes.splitButtonRight
                  )}
                  size="large"
                  variant="contained"
                  color="secondary"
                >
                  {t("common:buttons.shareResults")}
                </Button>
              </div>
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className={classes.imageColumn}
            >
              <div className={classes.imageContainer}>
                <Image
                  alt="Preparedness for the Future Icon"
                  src="/clipboard.svg"
                  layout="intrinsic"
                  height="200"
                  width="200"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid lg={12} md={12} sm={12} xs={12} item>
            <div className={classes.scoreColumn}>
              <p className={classes.scoreTitle}>
                {t("dashboard:prepFq.scoreTitle")}
              </p>
              {isLoading ? (
                <div className={classes.scoreLoader}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={60}
                    height={52}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={48}
                    height={29}
                  />
                </div>
              ) : (
                <p className={classes.score}>
                  {getScore(profile?.results.scaled_score)}
                  <span className={classes.scoreTotal}>/100</span>
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
      <StartPrepFqModal open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default PrepFQBanner;
