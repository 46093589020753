import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const dashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: "10% 0 6% 0",
      "@media (max-width: 600px)": {
        margin: "24px 0 24px 0",
      },
    },

    loadingGridSkeleton: {
      width: "100%",
      minHeight: 562,
      borderRadius: 20,
    },

    columnLeft: {
      // minHeight: minHeight,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: 72,
      paddingBottom: 36,
      "@media (max-width: 1000px)": {
        padding: "36px 0",
      },
    },
    columnLeftContents: {
      "@media (max-width: 1000px)": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    columnRight: {
      // minHeight: minHeight,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      "@media (max-width: 1000px)": {
        padding: "36px 0",
      },
    },
    imageColumn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "column",
      position: "relative",
    },
    imageContainer: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "absolute",
      top: -48,
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    imageContainerAlt: {
      "@media (max-width: 960px)": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    bannerTitle: {
      color: "#3E4066",
      fontStyle: "italic",
      fontSize: "28px",
      marginBottom: "36px",
    },
    bannerSubTitle: {
      color: "#3E4066",
      fontStyle: "italic",
      fontSize: "18px",
      textAlign: "right",
      marginRight: "25%",
      "@media (max-width: 960px)": {
        marginRight: 0,
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    blueText: {
      color: "#5db2e3",
    },
    paragraph: {
      maxWidth: "350px",
      fontSize: "16px",
      marginBottom: "36px",
      "@media (max-width: 960px)": {
        maxWidth: "100%",
      },
    },
    ctaButton: {
      backgroundColor: "#3E4066",
      marginBottom: "12px",
      maxWidth: "350px",
      fontSize: "17px !important",
      fontStyle: "italic !important",
      fontWeight: "400 !important" as any,
      "@media (max-width: 960px)": {
        fontSize: "14px !important",
      },
      "@media (max-width: 600px)": {
        maxWidth: 600,
      },
    },
    splitButtonContainer: {
      maxWidth: "350px",
      "@media (max-width: 600px)": {
        maxWidth: 600,
      },
    },
    splitButton: {
      padding: "8px 8px !important",
      width: "calc(50% - 4px)",
      fontStyle: "italic !important",
      fontWeight: "400 !important" as any,
    },
    splitButtonDisabled: {
      color: "white !important",
      boxShadow: "none !important",
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    },
    splitButtonRight: {
      borderRadius: "0 24px 24px 0",
      marginLeft: "4px",
    },
    splitButtonLeft: {
      borderRadius: "24px 0 0 24px",
      marginRight: "4px",
    },
    scoreColumn: {
      backgroundColor: "#CDDADD",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%",
      margin: "12px 0 30px",
      padding: "0px 24px 0 42px",
      minHeight: 96,
      "@media (min-width: 600px) and (max-width: 960px)": {
        padding: "0px 24px 0 24px",
      },
      "@media (max-width: 600px)": {
        padding: "0px 12px 0 12px",
      },
    },
    scoreColumnAlt: {},
    scoreColumnInner: {
      backgroundColor: "#CDDADD",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexDirection: "row",
      width: "50%",
      marginLeft: "30%",
      marginRight: "20%",
    },
    scoreColumnInnerAlt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    scoreTitle: {
      color: "#002655",
      fontSize: 24,
      lineHeight: "29px",
      fontWeight: "bold",
      maxWidth: 230,
      "@media (max-width: 600px)": {
        fontSize: 18,
        lineHeight: "19px",
      },
    },
    score: {
      color: "#002655",
      fontSize: 54,
      fontWeight: 900,
      marginRight: 72,
      position: "relative",
      "@media (max-width: 600px)": {
        fontSize: 34,
        lineHeight: "19px",
        marginRight: 36,
      },
    },
    percentage: {
      color: "#002655",
      fontSize: 54,
      fontWeight: 900,
      marginRight: 36,
      position: "relative",
      "@media (max-width: 600px)": {
        fontSize: 34,
        lineHeight: "19px",
        marginRight: 24,
      },
    },
    scoreTotal: {
      color: "#002655",
      fontSize: 34,
      lineHeight: "29px",
      fontWeight: "normal",
      position: "absolute",
      bottom: 28,
      left: 70,
      "@media (max-width: 600px)": {
        fontSize: 18,
        lineHeight: "19px",
        left: 40,
        bottom: 6,
      },
    },
    scoreDescription: {
      color: "#002655",
      fontSize: 18,
      maxWidth: "200px",
      lineHeight: "1.7",
    },
    scoreLoader: {
      display: "flex",
      flexDirection: "row",
    },
    ctaButtonLoader: {
      borderRadius: "24px",
      marginBottom: "12px",
    },
    dotsContainer: {
      borderRadius: "12px",
      padding: "0px 48px",
      marginTop: "12px",
    },
    dotsList: {
      margin: "0px",
    },
    dot: {
      transition: "0.2s ease-in-out",
      height: "15px",
      width: "15px",
      borderRadius: "10px",
    },
    dotSelected: {
      backgroundColor: "#002655",
    },
    bannerCard: {
      backgroundColor: "#E4EAEB",
      borderRadius: 20,
      zIndex: 2,
      margin: 12,
    },
    bannerCardContents: {
      padding: "42px 12px 42px 42px",
      minHeight: 400,
      "@media (max-width: 600px)": {
        padding: "24px 12px",
        minHeight: 200,
      },
    },
  })
);

export const carouselStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      // minHeight: "600px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dotsContainer: {
      borderRadius: "12px",
      padding: "0px 48px",
      marginTop: 20,
    },
    dotsList: {
      margin: 0,
      padding: 0,
    },
    dot: {
      transition: "0.2s ease-in-out",
      height: "15px",
      width: "15px",
      borderRadius: "10px",
      backgroundColor: "#E6ECEE",
    },
    dotSelected: {
      backgroundColor: "#002655",
    },
    row: {},
    itemRootFull: {
      width: 400,
      maxHeight: 400,
      "@media (max-width: 1200px)": {
        maxHeight: 250,
      },
    },
    itemContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  })
);
