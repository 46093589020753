import { Container, Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { carouselStyles, dashboardStyles } from "./styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import dynamic from "next/dynamic";

const AgeingCarousel = dynamic(
  () => {
    return import("../../components/AgeingCarousel");
  },
  { ssr: false }
);

const ImagesBanner = () => {
  const classes = carouselStyles();
  const dashboardClasses = dashboardStyles();
  const { t } = useTranslation(["dashboard"]);
  const carouselRef: any = React.createRef();
  const matched = useMediaQuery("(max-width:1200px)");
  const [currentSlide, setCurrentSlide] = useState(0);
  const getImageAge = (index) => {
    switch (index) {
      case 0:
        return "young";
      case 1:
        return "original";
      case 2:
        return "old";
    }
  };
  const getItems = () => {
    const items = [];
    Array(12)
      .fill(0)
      .forEach((number, index) => {
        Array(3)
          .fill(0)
          .forEach((n, ageIndex) => {
            items.push(
              <div
                key={`image_${index}_${ageIndex}`}
                className={classes.itemRootFull}
              >
                <div className={classes.itemContainer}>
                  <Image
                    src={`/ageing/${index + 1}_${getImageAge(ageIndex)}.jpg`}
                    objectFit="contain"
                    layout="intrinsic"
                    width={matched ? "250" : 400}
                    height={matched ? "250" : 400}
                    alt={`ageing - ${index + 1}`}
                  />
                </div>
              </div>
            );
          });
      });
    return items;
  };
  return (
    <React.Fragment>
      <AgeingCarousel
        ref={carouselRef}
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              centerMode: false,
              centerPadding: "0px",
              infinite: true,
              dots: true,
              autoplay: true,
              speed: 1500,
              autoplaySpeed: 5000,
              slidesToScroll: 3,
              beforeChange: (oldSlide, nextSlide) => {
                setCurrentSlide(nextSlide / 3);
              },
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              centerPadding: "0px",
              infinite: true,
              dots: false,
              autoplay: true,
              speed: 1000,
              autoplaySpeed: 2500,
              slidesToScroll: 1,
              beforeChange: (oldSlide, nextSlide) => {
                setCurrentSlide(nextSlide / 1);
              },
            },
          },
        ]}
        infinite={true}
        speed={2000}
        autoplay={true}
        autoplaySpeed={10000}
        slidesToShow={6}
        centerMode={false}
        centerPadding={0}
        dots={true}
        arrows={false}
        vertical={false}
        draggable={false}
        slidesToScroll={6}
        appendDots={(dots) => (
          <div className={classes.dotsContainer}>
            <ul className={classes.dotsList}> {dots} </ul>
          </div>
        )}
        customPaging={(i) => (
          <div
            className={
              currentSlide === i
                ? clsx(classes.dot, classes.dotSelected)
                : classes.dot
            }
          />
        )}
        beforeChange={(oldSlide, nextSlide) => {
          setCurrentSlide(nextSlide / 6);
        }}
      >
        {getItems()}
      </AgeingCarousel>
      <Container maxWidth="lg" style={{ marginTop: 100 }}>
        <h2
          className={dashboardClasses.bannerTitle}
          style={{ textAlign: "center", marginBottom: 24 }}
        >
          {`"${t("dashboard:images.title1")}"`}
        </h2>
        <h2 className={dashboardClasses.bannerSubTitle}>
          {t("dashboard:images.title2")}
        </h2>
      </Container>
    </React.Fragment>
  );
};

export default ImagesBanner;
