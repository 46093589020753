import React from "react";
import HomeRoot from "../src/views/dashboard";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation(["dashboard"]);
  return (
    <React.Fragment>
      <Head>
        <title>{t("dashboard:metaTitle")}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <HomeRoot />
    </React.Fragment>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "dashboard",
      "footer",
      "header",
    ])),
  },
});

export default Index;
