import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const dialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      marginRight: "60px",
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
    emptyTitle: {
      padding: 0,
      height: 0,
    },
    content: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    wideRoot: {
      maxWidth: "100%",
    },
    noPadding: {
      padding: "0px !important",
    },
    closeButton: {
      position: "absolute !important" as any,
      right: theme.spacing(4),
      top: theme.spacing(3),
      color: `${theme.palette.grey[500]} !important`,
      "@media (max-width: 960px)": {
        right: theme.spacing(3),
        top: theme.spacing(2),
      },
    },
    closeButtonLarge: {
      position: "absolute !important" as any,
      right: theme.spacing(3),
      top: theme.spacing(2),
      color: `${theme.palette.grey[500]} !important`,
      "@media (max-width: 960px)": {
        right: theme.spacing(2),
        top: theme.spacing(1),
      },
    },
  })
);
