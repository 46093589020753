import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { dialogStyles } from "../../theme/dialogStyles";
import { Button, Grid } from "@material-ui/core";
import Link from "next/link";
import { PREP_FQ } from "../../constants/routes";

interface OwnProps {
  open: boolean;
  setOpen(open: boolean): any;
}
type Props = OwnProps;

const StartPrepFqModal: React.FC<Props> = ({ open, setOpen }) => {
  const classes = dialogStyles();
  const { t } = useTranslation(["common", "header"]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="update-email-dialog-title"
      aria-describedby="update-email-dialog-description"
      keepMounted={false}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h3">{t("header:modals.prepFq.title")}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent style={{ paddingLeft: 16 }}>
        <Typography variant="body1" style={{ marginTop: 16 }}>
          {t("header:modals.prepFq.paragraph1")}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 12 }}>
          {t("header:modals.prepFq.paragraph2")}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 12 }}>
          {t("header:modals.prepFq.paragraph3")}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 12 }}>
          {t("header:modals.prepFq.paragraph4")}
        </Typography>
        <Grid
          spacing={1}
          style={{ marginTop: 24 }}
          container
          justify="flex-end"
          alignContent="center"
        >
          <Grid item />
          <Grid item>
            <Link passHref href={`${PREP_FQ}/0`}>
              <Button variant="contained" color="secondary" size="large">
                {t("common:buttons.letsGetStarted")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StartPrepFqModal;
