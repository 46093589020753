import React from "react";
import Container from "@material-ui/core/Container";
import { containerStyles } from "../../theme/containerStyles";
import PrepFQBanner from "./PrepFQBanner";
import ImagesBanner from "./ImagesBanner";
import { Grid } from "@material-ui/core";
import { dashboardStyles } from "./styles";

const HomeRoot = () => {
  const containerClasses = containerStyles();
  const classes = dashboardStyles();
  return (
    <React.Fragment>
      <section className={classes.root}>
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center">
            <Grid xs={12} sm={12} md={6} item>
              <PrepFQBanner />
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={containerClasses.root} style={{ minHeight: 0 }}>
        <div className={containerClasses.carousel} style={{ marginBottom: 0 }}>
          <ImagesBanner />
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomeRoot;
