import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: "0 0 150px 0",
      "@media (max-width: 1200px)": {
        margin: "0 0 120px 0",
      },
      position: "relative"
    },
    hero: {
      minHeight: 'calc(100vh - 125px - 12px)',
      "@media (max-width: 1200px)": {
        minHeight: 'calc(100vh - 77px - 12px)',
      },
      "@media (min-height: 1000px)": {
        minHeight: 800,
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      minHeight: "600px",
    },
    greyBackground: {
      backgroundColor: "#E4EAEB"
    },
    containerDense: {
      padding: 0
    },
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    carousel: {
      minHeight: "0 !important",
      marginBottom: 150,
      "@media (max-width: 1200px)": {
        marginBottom: 24,
      }
    }
  })
);
